import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'
import styled from 'styled-components'

export const StyledHomePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`

export const StyledWelcomeMessage = styled(Title)`
  &.ant-typography {
    font-family: Sora;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0px;
    text-align: left;
    color: #43043b;
    margin-bottom: 6px;
  }
`

export const StyledWelcomeDescriptionMessage = styled(Text)`
  //styleName: Text/Body/14px Regular;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #6b6b6b;
`

export const StyledHomePageContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 30px;

  @media (max-width: 1199px) {
    grid-template-columns: 1fr;
  }
`

export const StyledHomePageContentWrapperLeftSide = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledHomePageOrdersAndVisitsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  margin-top: 24px;
  margin-bottom: 18px;

  @media (max-width: 1199px) {
    grid-template-columns: 1fr;
    margin-bottom: 20px;
  }

  & .mobile-show {
    display: none !important;
    @media (max-width: 1199px) {
      display: flex !important;
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
`

export const StyledCartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 30px;
  background: #fff;
  box-shadow: 0px 3px 5px -1px rgba(67, 4, 59, 0.06);

  .styled-card-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #393939;
    margin-bottom: 9px;
  }

  .styled-card-price-value {
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    display: flex;
    align-items: center;

    color: #393939;
  }
`

export const StyledHomePageContentWrapperRightSide = styled.div`
  display: flex;
  flex-direction: column;

  & .mobile-hidden {
    @media (max-width: 1199px) {
      display: none !important;
    }
  }
`
export const StyledSpace = styled.div`
  display: 'flex';
  flex-direction: row;
  margin-bottom: 18px;
`

export const StyledChangeStoreStatus = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 30px 30px;
  background: #fff;
  box-shadow: 0px 3px 5px -1px rgba(67, 4, 59, 0.06);
  margin-top: 24px;
  margin-bottom: 18px;

  & .styled-card-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #393939;
    margin-bottom: 17px;
  }

  & .ant-btn {
    padding: 8px !important;
    height: auto;
    display: flex;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    color: #393939;
    line-height: initial;

    & .text {
      margin-right: auto;
      margin-left: 12.5px;
    }
  }

  button {
    max-width: 94px;
  }

  p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    /* Neutral / 60 - P */

    color: #6b6b6b;
    margin-top: 16px;
    margin-bottom: 0;
  }
`

export const StyledOrdersProcessing = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 30px 30px;
  background: #fff;
  box-shadow: 0px 3px 5px -1px rgba(67, 4, 59, 0.06);
  margin-top: 24px;
  margin-bottom: 18px;

  & .styled-card-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #393939;
    margin-bottom: 17px;
  }

  & .ant-btn {
    padding: 8px !important;
    height: auto;
    display: flex;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    color: #393939;
    line-height: initial;

    & .text {
      margin-right: auto;
      margin-left: 12.5px;
    }
  }
`

export const StyledOrdersProducts = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 30px;
  background: #fff;
  box-shadow: 0px 3px 5px -1px rgba(67, 4, 59, 0.06);

  & .title-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }

  & .styled-card-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #393939;
  }

  & .options-content .ant-btn {
    padding: 4px;
    height: auto;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }

  & .options-content .ant-btn.ant-btn-link {
    color: #393939;
  }

  & .most-selled-products {
    display: none;
  }

  & .most-selled-products.active {
    display: flex;
    flex-direction: column;
  }

  & .most-selled-products .title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #393939;
    padding: 0 12px;
  }

  & .most-selled-products .ant-divider-horizontal {
    margin: 9px 0;
    border-color: #e2e2e2;
  }

  & .products-list {
    display: flex;
    flex-direction: column;

    & .product .content {
      display: flex;
      flex-direction: row;
      padding: 0 12px;

      & .img {
        margin-right: 10px;
        width: 47px;
        height: 64px;
        & img {
          object-fit: cover;
          height: 100%;
        }
      }

      & .product-detail {
        display: flex;
        flex-direction: column;

        & .product-name {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 24px;
          color: #6b6b6b;
        }

        & .product-qty {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #6b6b6b;
        }
      }
    }

    & .product:last-child .ant-divider-horizontal {
      display: none;
    }
  }
`

export const StyledOrdersHistory = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 30px;
  background: #fff;
  box-shadow: 0px 3px 5px -1px rgba(67, 4, 59, 0.06);

  @media (max-width: 1199px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1200px) and (max-width: 1500px) {
    max-width: 800px;
  }

  .styled-card-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #393939;

    @media (max-width: 1199px) {
      margin-bottom: 20px;
    }
  }

  & .title-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  & .options-content .ant-btn {
    padding: 4px;
    height: auto;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }

  & .options-content .ant-btn.ant-btn-link {
    color: #393939;
  }
`
